import React from "react";
import ButtonTestModal from "../components/ButtonTestModal";
import Card from "../components/Card";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import SEO from "../components/seo";

function Planos() {
  return (
    <Layout>
      <SEO title="AtendeJá" />
      <h1 className="text-center font-bold mx-5 xl:mx-64 my-24">
        Conheça nossos planos e veja qual se encaixa melhor ao seu negócio
      </h1>

      <div className="flex grid md:grid-cols-2 xl:grid-cols-4 gap-4 mx-5 md:mx-16 lg:mx-40 xl:mx-0 lg:my-12">
        <Card
          className="col-span-1 xl:col-start-2"
          icon="award"
          title="Mensal"
          description="Criação de atendimentos, agendamentos, monitoramento e muito mais assinado mensalmente."
        >
          <ButtonTestModal>ENTRAR EM CONTATO</ButtonTestModal>
        </Card>
        <Card
          className="col-span-1 xl:col-start-3"
          discount
          icon="trophy"
          title="Anual"
          description="Criação de atendimentos, agendamentos, monitoramento e muito mais assinado anualmente."
        >
          <ButtonTestModal>ENTRAR EM CONTATO</ButtonTestModal>
        </Card>
      </div>

      <div className="my-10 pb-8 text-center mx-10">
        <small className="text-gray">
          ¹ Implantação e treinamentos são serviços opcionais com custo a parte.
        </small>
        <br />
        <small className="text-gray">
          ² Cada usuário tem um valor adicional por mês.
        </small>
      </div>

      <div className="background-white py-24">
        <h1 className="text-center font-bold mx-5 lg:mx-64">
          Perguntas frequentes
        </h1>
        <div className="lg:flex lg:mx-24 xl:mx-56">
          <div className="mx-10 lg:w-1/2">
            <h3 className="font-bold mt-8 mb-2">Como o AtendeJá funciona?</h3>
            <p className="text-gray">
              O AtendeJá é um software em nuvem, que pode ser usado pelo
              navegador. Não é necessário instalar nada no computador para poder
              usá-lo. Assim como outros serviços de assinatura, o AtendeJá cobra
              um valor mensal pela quantidade de usuários na conta.
            </p>
            <h3 className="font-bold mt-8 mb-2">
              Quais as formas de pagamento?
            </h3>
            <p className="text-gray">
              Aceitamos boleto e todos os cartões de crédito. No boleto, pode
              levar até 3 dias úteis para que o pagamento seja compensado e a
              conta liberada.
            </p>
            <h3 className="font-bold mt-8 mb-2">
              Vocês emitem nota fiscal sobre a aquisição do AtendeJá?
            </h3>
            <p className="text-gray">
              Sim, logo após a confirmação do pagamento.
            </p>
          </div>
          <div className="mx-10 lg:w-1/2">
            <h3 className="font-bold mt-8 mb-2">
              Minhas informações estarão seguras no AtendeJá?
            </h3>
            <p className="text-gray">
              Utilizamos os Bancos de Dados da Amazon, que é a mesma plataforma
              que os bancos utilizam. É considerada uma das plataformas mais
              seguras da internet, utilizada pelas principais empresas do mundo.
            </p>
            <h3 className="font-bold mt-8 mb-2">
              Não tenho tempo para implementar, o que eu faço?
            </h3>
            <p className="text-gray">
              Caso seja necessário, possuímos uma equipe para te auxiliar nessa
              etapa. Vamos garantir as configurações necessárias para o início
              do uso do software, além de ensinar as melhores práticas de uso do
              sistema.
            </p>
          </div>
        </div>
      </div>

      <InformationContent
        className="mx-5 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no AtendeJá"
        description="Faça parte das empresas que estão crescendo com o AtendeJá e comece a obter melhores resultados"
        subTextColor="gray"
      />
    </Layout>
  );
}

export default Planos;
